import React from "react";
import BcrumbBannerOne from "../../elements/breadcrumb/BcrumbBannerOne";
import CaseStudyProp from "./CaseStudyProp";

const CaseStudy = () => {
  return (
    <div id="case-study">
      <BcrumbBannerOne
        title="Case Studies"
        paragraph="We design and develop web and mobile applications for our clients worldwide."
        styleClass="thumbnail-3"
        mainThumb="/images/others/clock.png"
      />
      <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
        <div className="container">
          <CaseStudyProp />
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
