import React from "react";
import ImageSlider from "../../elements/slider";
import CaseStudyData from "../../data/casestudy/CaseStudyData.json";

const allData = CaseStudyData;

const CaseStudyProp = () => {
  const CaseLeftThumb = ({ data }) => {
    return (
      <>
        {data.subtitle && (
          <div className="col-lg-5">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">{data.subtitle}</span>
                <div className="logo">
                  <img src={process.env.PUBLIC_URL + data.logo} alt="Logo" />
                </div>
                <p>{data.excerpt}</p>
              </div>
            </div>
          </div>
        )}
        <div className="col-lg-7">
          <div className={`case-study-featured-thumb thumb-${data.id}`}>
            <ImageSlider data={data.images} />
          </div>
        </div>
      </>
    );
  };

  const CaseRightThumb = ({ data }) => {
    return (
      <>
        {data.subtitle && (
          <div className="col-lg-5 order-lg-2">
            <div className="case-study-featured">
              <div className="section-heading heading-left">
                <span className="subtitle">{data.subtitle}</span>
                <div className="logo">
                  <img src={process.env.PUBLIC_URL + data.logo} alt="Logo" />
                </div>
                <p>{data.excerpt}</p>
              </div>
            </div>
          </div>
        )}
        <div className="col-lg-7 order-lg-1">
          <div className={`case-study-featured-thumb thumb-${data.id}`}>
            <ImageSlider data={data.images} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {allData.map((data) => (
        <div className="row" key={data.id} id={data["section-id"]}>
          {data.id % 2 === 1 ? (
            <CaseRightThumb data={data} />
          ) : (
            <CaseLeftThumb data={data} />
          )}
        </div>
      ))}
    </>
  );
};

export default CaseStudyProp;
