import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

const Result = ({ type, message }) => {
  return (
    <Alert variant={type}>
      {/* Your Message has been successfully sent. */}
      {message}
    </Alert>
  );
};

const FormOne = () => {
  const form = useRef();
  const [result, setResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4wdwy9s", // service id
        "template_da6jbvf", // template id
        form.current,
        "gt97tXcr6o0yWMtFs"
      )
      .then(
        (result) => {
          setResult({
            type: "success",
            message: result.text,
          });
          // Not to reset on error
          form.current.reset();
        },
        (error) => {
          setResult({
            type: "danger",
            message: error.text,
          });
          console.error(error.text);
        }
      )
      .finally(() => {
        setTimeout(() => {
          setResult(null);
        }, 5000);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="contact-name"
          placeholder="John Smith"
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact-email"
          placeholder="example@mail.com"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          placeholder="+123456789"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Note</label>
        <textarea
          name="note"
          className="form-control"
          cols="40"
          rows="5"
        ></textarea>
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Get Free Quote
        </button>
      </div>
      <div className="form-group">{result && <Result {...result} />}</div>
    </form>
  );
};

export default FormOne;
