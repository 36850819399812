import React from "react";
import Slider from "react-slick";
import Tilty from "react-tilty";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

const ImageSlider = ({ data, type = "" }) => {
  function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleRight />
      </div>
    );
  }

  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FaAngleLeft />
      </div>
    );
  }

  var slideSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
  };

  return (
    <Slider
      {...slideSettings}
      className={
        type === "portfolio" ? "slick-portfolio-nav" : "slick-arrow-nav"
      }
    >
      {data.map((url, index) => (
        <div className="slide-item" key={index}>
          {type === "portfolio" ? (
            <img src={`${process.env.PUBLIC_URL}/${url}`} alt="Case study" />
          ) : (
            <Tilty perspective={2000}>
              <div className="slide-item-container">
                <img
                  src={`${process.env.PUBLIC_URL}/${url}`}
                  alt="Case study"
                />
              </div>
            </Tilty>
          )}
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
