import React from "react";
import ImageSlider from "../../../elements/slider";

const PropOne = ({ projectStyle, portfolio }) => {
  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail">
          <ImageSlider data={portfolio.images} type="portfolio" />
        </div>
        <div className="content">
          <h4 className="title">{portfolio.title}</h4>
          <span className="subtitle">{portfolio.subTitle}</span>
        </div>
      </div>
    </>
  );
};

export default PropOne;
