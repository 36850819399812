import React from "react";
import SEO from "../common/SEO";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import Footer from "../common/footer/Footer";
import ClientSection from "../component/brand/ClientSection";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import Project from "../component/project/Project";
import ServiceSection from "../component/service/ServiceSection";
import SectionTitle from "../elements/section-title/SectionTitle";
import Banner from "../component/banner/Banner";
import SplashHeader from "../common/header/SplashHeader";
import CaseStudy from "../component/casestudy";
import TeamSection from "../component/team/TeamSection";

const CorporateAgency = () => {
  return (
    <>
      <SEO title="AA Design & Development LLC" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <SplashHeader />
        <Banner />
        <div className="section section-service section-padding" id="services">
          <div className="container">
            <SectionTitle
              subtitle="What We Can Do For You"
              title="Services we can <br> help you with"
              description=""
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />
            <div className="row">
              <ServiceSection
                colSize="col-lg-4"
                serviceStyle="service-style-2"
                itemShow="3"
                marginTop="yes"
              />
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
          <ul className="shape-group-4 list-unstyled">
            <li className="clock">
              <img
                src={process.env.PUBLIC_URL + "/images/others/client.png"}
                alt="clock"
              />
            </li>
          </ul>
        </div>
        <CaseStudy />
        <Project parentClass="bg-color-light" />
        <ClientSection />
        <TeamSection />
        <CtaLayoutOne />
        <Footer />
      </main>
    </>
  );
};

export default CorporateAgency;
