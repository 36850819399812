import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";

// Home Pages Import
import CorporateAgency from "./pages/CorporateAgency";

// Pages
import ErrorPage from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// Css Import
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/app.scss";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/"}
            element={<CorporateAgency />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/404"}
            element={<ErrorPage />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/coming-soon"}
            element={<ComingSoon />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/privacy-policy"}
            element={<PrivacyPolicy />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/terms-use"}
            element={<TermsOfUse />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
