import React from "react";
import { FaLinkedin } from "react-icons/fa";
import TeamData from "../../data/team/TeamData.json";

const allData = TeamData;

const TeamSection = () => {
  return (
    <div className="section section-padding-equal" id="teams">
      <div className="container">
        <div className="section-heading">
          <span className="subtitle">Our Leadership</span>
          <h2 className="title mb--50">Meet The Team</h2>
          <p>
            Our team has vast experience building everything from startups to
            large enterprise platforms and systems that scale with the latest
            technology. We can fully manage a software team for you or embed
            engineers on your existing team and scale up to as many resources as
            your company requires.
          </p>
        </div>
        <div className="row team-container">
          {allData.map((data) => (
            <div className="col-xl-4 col-sm-6" key={data.id}>
              <div className="team-grid">
                <div className="thumbnail">
                  <div className="cover">
                    <img
                      width="512px"
                      src={process.env.PUBLIC_URL + data.thumb}
                      alt={data.title}
                    />
                  </div>
                </div>
                <div className="content">
                  <h4 className="title">{data.title}</h4>
                  {data.social.linkedin && (
                    <div className="footer-social-link">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            href={data.social.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaLinkedin />
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                  <span className="designation">{data.designation}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="shape-group-1 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
            alt="bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
            alt="bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-2.png"}
            alt="bubble"
          />
        </li>
        <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
            alt="bubble"
          />
        </li>
      </ul>
    </div>
  );
};

export default TeamSection;
