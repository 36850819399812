import React, { useState, useEffect } from "react";
import ProjectPropOne from "./itemProp/ProjectPropOne";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectData from "../../data/project/ProjectData.json";

const AllData = ProjectData;

const Project = ({ parentClass, colSize, itemShow, columnGap }) => {
  const [getAllItems] = useState(AllData);
  const [visiableProject] = useState(itemShow ? itemShow : 8);
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setVisibleItems(getAllItems.filter((item) => item.id <= visiableProject));
  }, []);

  return (
    <div
      className={`section section-padding-2 ${parentClass ? parentClass : ""}`}
      id="portfolio"
    >
      <div className="container">
        <SectionTitle
          subtitle="Our Portfolio"
          title="Some of our <br>
                        finest work."
          textAlignment="heading-left mb--40"
          textColor=""
        />
        <div className={`row ${columnGap ? columnGap : "row-35"}`}>
          {visibleItems.map((data) => (
            <div
              className={colSize ? colSize : "col-md-6"}
              key={data.id}
              id={data["section-id"]}
            >
              <ProjectPropOne projectStyle="" portfolio={data} />
            </div>
          ))}
        </div>
      </div>
      <ul className="shape-group-7 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
            alt="circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
            alt="Line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
            alt="Line"
          />
        </li>
      </ul>
    </div>
  );
};

export default Project;
