import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          <Link to="#">
            Services <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link smooth to="/#services">
                Design
              </Link>
            </li>
            <li>
              <Link smooth to="/#services">
                Development
              </Link>
            </li>
            <li>
              <Link smooth to="/#services">
                Strategy
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <Link to="#">
            Clients <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link smooth to="/#comcast">
                Comcast
              </Link>
            </li>
            <li>
              <Link smooth to="/#opticus">
                Opticus
              </Link>
            </li>
            <li>
              <Link smooth to="/#ey">
                EY
              </Link>
            </li>
            <li>
              <Link smooth to="/#portfolio">
                Upstock
              </Link>
            </li>
            <li>
              <Link smooth to="/#shipmate">
                Shipmate
              </Link>
            </li>
            <li>
              <Link smooth to="/#omega">
                Omega
              </Link>
            </li>
            <li>
              <Link smooth to="/#people">
                People Metrics
              </Link>
            </li>
            <li>
              <Link smooth to="/#victiv">
                Victiv
              </Link>
            </li>
            <li>
              <Link smooth to="/#marketful">
                Marketful
              </Link>
            </li>
            <li>
              <Link smooth to="/#tune">
                Tune.fm
              </Link>
            </li>
            <li>
              <Link smooth to="/#clients">
                Bank of America
              </Link>
            </li>
            <li>
              <Link smooth to="/#clients">
                Capital One
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-item-has-children">
          <Link smooth to="/#">
            Team <FaAngleDown />
          </Link>
          <ul className="axil-submenu">
            <li>
              <Link smooth to="/#teams">
                Andrew
              </Link>
            </li>
            <li>
              <Link smooth to="/#teams">
                Daniil
              </Link>
            </li>
            <li>
              <Link smooth to="/#teams">
                Igor
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
