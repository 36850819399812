import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={process.env.PUBLIC_URL + "/"}>
      <img
        width="80px"
        className="light-version-logo"
        src={process.env.PUBLIC_URL + "/images/logo.png"}
        alt="logo"
      />
    </Link>
  );
};

export default Logo;
