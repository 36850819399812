import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import BrandItem from "./BrandItem";

const ClientSection = () => {
  return (
    <div className="section section-padding" id="clients">
      <div className="container">
        <SectionTitle
          subtitle="Top Clients"
          title="We’ve built solutions for..."
          description="Small startups to large enterprise clients."
          textAlignment="heading-dark-left"
          textColor=""
        />
        <div className="row clients">
          <BrandItem />
        </div>
      </div>
    </div>
  );
};

export default ClientSection;
